import React from 'react';
import './Contact.css'

const Contact= () => {
  return (
   
      <section className="contact-address-area " >
        <div className="container-fluid " style={{ backgroundColor:"#f4f4f5"}}>
         
          <div className="sec-title-style1 float-left text-center">
                      <div className="title">Contact Us</div>
                      <div className="text">
                        <div className="decor-left">
                          <span></span>
                        </div>
                        <p>Contact Form</p>
                        <div className="decor-right">
                <span></span>
              </div>
                      </div>
                    </div>
                   
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12" >
              <div className="contact-form" style={{ backgroundColor:"#f4f4f5"}}>
                <div className="row">
                  <div className="col" >
                <div className="inner-box" style={{ backgroundColor:"#f4f4f5"}}>
                  <form
                    id="contact-form"
                    name="contact_form"
                    className="default-form"
                    action="#"
                    method="POST"
                  >
                    <div className="row">
                      <div className="col-xl-6 col-lg-12">
                        <div className="row">
                          <div className="col-xl-6">
                            <div className="input-box">
                              <input type="text" name="form_name"  placeholder="Name" required="" />
                            </div>
                            <div className="input-box">
                              <input type="text" name="form_phone"  placeholder="Phone" />
                            </div>
                          </div>
                          <div className="col-xl-6">
                            <div className="input-box">
                              <input type="email" name="form_email"  placeholder="Email" required="" />
                            </div>
                            <div className="input-box">
                              <input type="text" name="form_website"  placeholder="WhatsApp Number" />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-12">
                            <div className="input-box">
                              <input type="text" name="form_subject"  placeholder="Subject" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-12">
                        <div className="input-box">
                          <textarea name="form_message" placeholder="Your Message..." required=""></textarea>
                        </div>
                        <div className="button-box">
                          <input id="form_botcheck" name="form_botcheck" className="form-control" type="hidden" value="" />
                          <button type="submit" data-loading-text="Please wait...">
                            Send Message
                            <span className="flaticon-next"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
     
      </section>
 
 
  );
};

export default Contact;
