import React from 'react';
import './cardes.css';

const containerStyle = {
  height: '80vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: '"Raleway", Arial, sans-serif',
  backgroundColor: '#f4f4f5',
};

function About() {
  return (
    <>
      <div className='container-fluid '>
        <div className='row'>
          {/* Contact Us Section */}
          <div
            className="sec-title-style1 float-left text-center"
            style={{ backgroundColor: '#f4f4f5' }}
          >
            <div className="title">About US</div>
            <div className="text">
              <div className="decor-left">
                <span></span>
              </div>
              <p>About us</p>
              <div className="decor-right">
                <span></span>
              </div>
            </div>
          </div>
          {/* First Card Column (sm=12 on small screens, md=6 on medium screens, lg=6 on large screens) */}
          <div className='col' sm={12} md={6} lg={6} style={containerStyle}>
            <div className="cardes py-5">
            <div className="imgboxes">
                <img
                  src="https://img1.wsimg.com/isteam/stock/Ykpoj5Y?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                  alt="Smiling person"
                  style={{
                    width: '100%',
                    borderRadius: '10px',
                    boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.5)',
                  }}
                />
                <h3 className="cont">Technical Experience</h3>
              </div>

              <div className="contentes">
                <p className="text-muted">
                  We are well-versed in a variety of operating systems,
                  networks, and databases. We work with just about any
                  technology that a small business would encounter. We use
                  this expertise to help customers with small to mid-sized
                  projects.
                </p>
              </div>
            </div>
          </div>
          {/* Second Card Column (sm=12 on small screens, md=6 on medium screens, lg=6 on large screens) */}
          <div className='col' sm={12} md={6} lg={6} style={containerStyle}>
            <div className="cardes py-5">
            <div className="imgboxes">
                <img
                  src="https://img1.wsimg.com/isteam/stock/2503/?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                  alt="Smiling person"
                  style={{
                    width: '100%',
                    borderRadius: '10px',
                    boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.5)',
                  }}
                />
                <h3 className="cont">Satisfaction Guaranteed</h3>
              </div>

              <div className="contentes">
                <p className="text-muted">
                  The world of technology can be fast-paced and scary.
                  That's why our goal is to provide an experience that is tailored to your company's needs.
                  No matter the budget, we pride ourselves on providing professional customer service.
                  We guarantee you will be satisfied with our work.
                </p>
              </div>
            </div>
            </div>
            </div>
            </div>
          
         
    </>
  );
}

export default About;
