import React from 'react'
import Slider from '../pages/Slider';
import About from '../pages/About';
import Photo from '../pages/Photo';
import Batch from  '../pages/Batch';
import Contact from '../pages/Contact';
import Courses from '../pages/Courses';


const MainPage = () => {
  return (
   <>
   
<Slider/>
<About/>
<Photo />
<Batch/>
<Courses/>
<Contact/>

   
   </>
  )
}

export default MainPage