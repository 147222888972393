


import { Routes, Route } from "react-router-dom";
import Slider from './pages/Slider';
import Navbar from './pages/Navbar';
import About from './pages/About';
import Photo from './pages/Photo'
import Contact from './pages/Contact';
import Footers from './pages/Footers';
import Batch from './pages/Batch';
import MainPage from "./pages/MainPage";
import Courses from './pages/Courses';
import { HashRouter as Router } from 'react-router-dom';
import './App.css'


function App() {
  return (
  <>
 <Router>
  <Navbar/>
  <Routes>
  <Route path="/" exact element={<MainPage />} />
  <Route path="/slider" exact element={<Slider />} />
  <Route path="/about" exact element={<About />} />
  <Route path="/photo" exact element={<Photo />} />
  <Route path="/batch" exact element={<Batch />} />
  <Route path="/courses" exact element={<Courses />} />
  <Route path="/contact" exact element={<Contact />} />
  </Routes>
  <Footers/>
  </Router>


 {/* <Navbar/>
  <Slider/>
  <About/>
<Photo />
<Batch/>
<Contact/>
<Footers/> */}
 
  
  </>
  );
}

export default App;
