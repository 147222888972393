import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';

import wall from '../assert/wall.jpeg';

// import wall4 from '../assert/wall4.jpg';

function MyCarousel() {
  const carouselData = [
  
    {
      id: 2,
      imageSrc: wall,
      title: 'PROFESSIONAL ONLINE CLASSES',
      description: 'Unlock your potential for IT with our customized real-world solution',
    },
    {
      id: 3,
      imageSrc: wall,
      title: 'PROFESSIONAL ONLINE CLASSES',
      description: 'Unlock your potential for IT with our customized real-world solution',
    },
    
  ];

  const captionStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    padding: '10px',
    textAlign: 'center',
  };
  

  return (
  <div className='conatiner-fluid' style={{overflowX:"hidden",overflowY:"hidden"}}>
    <div className='row'>
      <div className='col '>
      {/* interval={null} */}
      <Carousel interval={2000} >
      {carouselData.map((item) => (
        <Carousel.Item key={item.id} className='text-center'>
          <img
            className="d-block w-100"
            src={item.imageSrc}
            alt={item.title}
            style={{ maxHeight: '600px', objectFit: 'cover' }}
          />
          <div style={captionStyle} >
            <h1 className='titleStyle' style={{fontSize:"70px",whiteSpace:"nowrap"}}>{item.title}</h1>
            <h4 className='' style={{fontSize:"12px",whiteSpace:"nowrap"}}>{item.description}</h4>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
      </div>
    </div>
  </div>
  );
}

export default MyCarousel;
