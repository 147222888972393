import React from 'react';
import './Courses.css';
import logo1 from '../assert/logo1.svg'
import logo2 from '../assert/logo2.svg'
import logo3 from '../assert/logo3.svg'

function Courses() {
  return (
    <section id="advertisers" className="advertisers-service-sec pt-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="sec-title-style1 float-left text-center">
            <div className="title">Our Online Courses</div>
            <div className="text">
              <div className="decor-left">
                <span></span>
              </div>
              <p>Online Courses</p>
              <div className="decor-right">
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 mt-md-4 row-cols-1 row-cols-sm-2 row-cols-md-3 justify-content-center">
          <div className="col">
            <div className="service-card">
              <div className="icon-wrapper">
                <img src={logo1} alt="logo1" className="img-fluid" />
              </div>
              <h3>Linux Course</h3>
              <p className='text-muted'>
                linux os installation, network configuration, file structure of Linux, user management group management, installing software, text editor (nano, vi, vim, etc), find, sed, tar, gzip, unzip, cat, cp, mkdir, touch. zcat, head, tail, grep, rsync, scp, Linux filesystem (fdisk, parted), file permission (special permission), process management, log management.
              </p>
            </div>
          </div>
          <div className="col">
            <div className="service-card">
              <div className="icon-wrapper">
                <img src={logo2} alt="logo2" className="img-fluid" />
              </div>
              <h3>SERVERS</h3>
              <p className='text-muted'>
                yum server, ssh server, samba server and (advance samba), nfs server, Apache (http) (https), lvm, server backup, MySQL server management, git server, xampp or lampp servers.
              </p>
            </div>
          </div>
          <div className="col">
            <div className="service-card">
              <div className="icon-wrapper">
                <img src={logo3} alt="logo3" className="img-fluid" />
              </div>
              <h3>CLOUDS AWS</h3>
              <p className='text-muted'>
                i am, ec2, elastic IPs, code commit, security groups, load balancers, cost optimization, snapshot, s3, cloud front, and much more to turn normal job into AWS. Also, some basic knowledge about other cloud infra, how to do OS patching, how to deploy WordPress step by step from scratch.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Courses;
